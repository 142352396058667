import { m__mock, mock, Random } from '@chinamo/mock';

export const { lines } = mock({
  lines: [
    {
      go: [
        {
          startStation: '城厢站',
          endStations: [{ name: '沙市南站' }, { name: '滠口站' }, { name: '香炉山站' }, { name: '吴家山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '公兴站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '普兴站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '大弯镇站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '皂角铺站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '黄许镇站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '眉山站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '鲜滩站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '青龙场站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '乐山北站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '沙湾站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '金口河站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '资阳站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '达州站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '河市坝站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '广元南站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '安家湾站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '迤资站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '青杠站',
          endStations: [{ name: '沙市南站' }, { name: '滠口站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '渡口站',
          endStations: [{ name: '沙市南站' }, { name: '滠口站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '攀枝花站',
          endStations: [{ name: '沙市南站' }, { name: '滠口站' }, { name: '香炉山站' }, { name: '大花岭站' }],
          display: true
        },
        {
          startStation: '西昌南站',
          endStations: [{ name: '沙市南站' }, { name: '香炉山站' }],
          display: true
        }
      ],
      back: [
        {
          startStation: '沙市南站',
          endStations: [{ name: '城厢站' }, { name: '公兴站' }, { name: '普兴站' }, { name: '大弯镇站' }, { name: '皂角铺站' }, { name: '黄许镇站' }, { name: '眉山站' }, { name: '鲜滩站' }, { name: '青龙场站' }, { name: '乐山北站' }, { name: '沙湾站' }, { name: '金口河站' }, { name: '资阳站' }, { name: '达州站' }, { name: '河市坝站' }, { name: '广元南站' }, { name: '安家湾站' }],
          display: true
        },
        {
          startStation: '香炉山站',
          endStations: [{ name: '城厢站' }, { name: '公兴站' }, { name: '普兴站' }, { name: '大弯镇站' }, { name: '皂角铺站' }, { name: '黄许镇站' }, { name: '眉山站' }, { name: '鲜滩站' }, { name: '青龙场站' }, { name: '乐山北站' }, { name: '沙湾站' }, { name: '金口河站' }, { name: '资阳站' }, { name: '达州站' }, { name: '河市坝站' }, { name: '广元南站' }, { name: '安家湾站' }],
          display: true
        },
        {
          startStation: '滠口站',
          endStations: [{ name: '城厢站' }],
          display: true
        },
        {
          startStation: '大花岭南站',
          endStations: [{ name: '城厢站' }, { name: '普兴站' }],
          display: true
        },
        {
          startStation: '吴家山站',
          endStations: [{ name: '城厢站' }],
          display: true
        },
        {
          startStation: '香炉山站',
          endStations: [{ name: '普兴站' }, { name: '眉山站' }, { name: '青龙场站' }, { name: '乐山北站' }, { name: '广元南站' }, { name: '广汉站' }],
          display: true
        },
        {
          startStation: '罗家桥站',
          endStations: [{ name: '普兴站' }, { name: '眉山站' }, { name: '青龙场站' }, { name: '安家湾站' }, { name: '乐山北站' }, { name: '广元南站' }, { name: '广汉站' }],
          display: true
        },
        {
          startStation: '岳阳北站',
          endStations: [{ name: '普兴站' }, { name: '眉山站' }, { name: '青龙场站' }, { name: '乐山北站' }, { name: '广元南站' }, { name: '广汉站' }, { name: '达州站' }, { name: '皂角铺站' }, { name: '南充东站' }, { name: '江油站' }, { name: '城厢站' }, { name: '黄许镇站' }, { name: '燕岗站' }, { name: '资阳站' }, { name: '青白江站' }, { name: '安家湾站' }],
          display: true
        },
        {
          startStation: '沙市南站',
          endStations: [{ name: '连界站' }],
          display: true
        },
        {
          startStation: '香炉山站',
          endStations: [{ name: '连界站' }],
          display: true
        },
        {
          startStation: '滠口站',
          endStations: [{ name: '连界站' }],
          display: true
        },
        {
          startStation: '鄂州北站',
          endStations: [{ name: '连界站' }],
          display: true
        },
        {
          startStation: '岳阳北站',
          endStations: [{ name: '连界站' }, { name: '达州站' }, { name: '沙湾站' }, { name: '公兴站' }],
          display: true
        },
        {
          startStation: '岳阳南站',
          endStations: [{ name: '连界站' }, { name: '达州站' }, { name: '沙湾站' }, { name: '公兴站' }],
          display: true
        },
        {
          startStation: '岳阳北站',
          endStations: [{ name: '黄许镇站' }, { name: '鲜滩站' }, { name: '眉山站' }, { name: '连界站' }],
          display: true
        },
        {
          startStation: '岳阳南站',
          endStations: [{ name: '黄许镇站' }, { name: '鲜滩站' }, { name: '眉山站' }, { name: '连界站' }],
          display: true
        }
      ]
    }
  ]
});

export const _lines = m__mock(lines);
